.HomePage{
    display: flex;
    justify-content: space-between;
    color: aliceblue;
}

.left{
    display: flex;
    align-self: flex-start;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

/* The best club in the town*/
.the-best-club{
    display: flex;
    margin-top: 4rem;
    margin-left: 2rem;
    background-color: #062438;
    border-radius: 4rem;
    width:fit-content;
    padding: 1.5rem 0.8125rem;
    padding-left: 2rem;
    text-transform: uppercase;
    color: antiquewhite;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.the-best-club>span{
    z-index: 2;
}

.the-best-club>div{
    position:absolute;
    margin-left: 2rem;
    background-color: rgba(255, 122, 33, 0.922);
    width: 5rem;
    height: 5%;
    left: 0.5rem;
    border-radius: 3rem;
    z-index: 1;
}

/* Shape your body text */
.shape-body{
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: azure;
    text-overflow: inherit;
    position: relative;
}

.shape-body>div:nth-of-type(3){
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

/*figures & numbers */
.figures{
    display: flex;
    gap: 2rem;
    margin-left: 2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: azure;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: rgb(128, 128, 128);
    text-transform: uppercase;
}

/* Buttons */
.homepage-buttons{
    margin-left: 2rem; 
    display: flex;
    font-weight: normal;
    
}

.homepage-buttons>:nth-child(1){ /* Button : Lets begin */
    color: azure;
    background-color: orange;
    width: 8rem;
    border-radius: 1rem;
}

.homepage-buttons>:nth-child(2){ /* Button : learn more */
    border-radius: 1rem;
    border: 2px solid orange;
    background-color: transparent;
    color: azure;
    width: 8rem;
    margin-left: 2rem; /* this gives space between 2 buttons*/

}

/* Buttons general class */
.btn{
    background-color: azure;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn:hover{
    cursor:pointer;
}







.right{
    display: flex;
    flex: 1;
    position: relative;
    background-color: rgb(221, 143, 0);
}

.right>:nth-child(1){
    margin-top: 4rem;
    margin-left: 4rem;
    color: rgb(27, 17, 0);
    background-color:azure;
    width: 8rem;
    border-radius: 1rem;
    position: absolute;
    right: 3rem;
    top: -1.55rem;
    color: rgb(0, 0, 0);
}

.right_image{
    display: flex;
    position: absolute;
    right: 7rem;
    top:10rem;
}

.right_image>img{
    width: 30rem;
}

/*
.heart{
    position:absolute;
    display: flex;
    flex-direction: column;
    top: 7.5rem;
    left: 5rem;
    border-radius: 2rem;
    border: 3px solid black;
    margin-top: 4rem;
    margin-left: 2rem;
    background-color: #062438;
    width:fit-content;
    padding: 20px 13px;
    padding-left: 2rem;
    text-transform: uppercase;
    color: antiquewhite;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
*/

.heart{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    background-color: #062438;
    border-radius: 30px;
    border: 3px solid rgb(159, 0, 0);
    position: absolute;
    left: 8rem;
    top: 8rem;
}

.heart>img{
    width: 2rem;
}

.heart>:nth-child(2){
    color: gray;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.heart>:nth-child(3){
    color: rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.arrow3{
    position:absolute;
    display: flex;
    top: 23.25rem;
    right: 30rem;
    width: 11rem;
    z-index: -1;
}

.blur-homepage{
    width: 22rem;
    height: 30rem;
    left: 10rem;
}


@media screen and (max-width: 988px){

    html,body{
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
    
    .HomePage{
        flex-direction: column;
        position: absolute;
    }
    .left{
        padding: 1rem;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        width:fit-content;
    }
    .blur-homepage{
        width: 15rem;
        margin-left: -2rem;
    }
    .the-best-club{
        margin-top: 0rem;
        margin-left:0rem;
        margin-right: 0rem;
        align-self: center;
        font-size: medium;
        transform:scale(0.8);
    }

    .the-best-club>:nth-child(1){
        height: 2rem;
        margin-left: 0;
        margin-right: 0;
        transform:scale(0.8);
    }

    .shape-body{
        margin-left: 0rem;
        align-items: center;
        text-align: center;
        display:flex;
        width:fit-content;
        font-size: 3rem;
        flex-direction:column;
        flex-wrap:wrap;
    }
    .shape-body>div:nth-child(3){
        font-size:large;
        width:fit-content;
        align-items: center;
        text-align: center;
        letter-spacing: 1px;
        font-weight: 200;
    }
    .figures{
        align-items: center;
        align-self: center;
        align-content: center;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .figures>div>span:nth-child(1){
        text-align: center;
        font-size: large;
    }
    .figures>div>span:nth-child(2){
        text-align: center;
        font-size: small;
    }

    .homepage-buttons{
        align-self: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-left: 0rem;
    }

    .right{
        position: relative;
        background: none;
    }
    .heart{
        position: relative;
        left: 1rem;
        top: 2rem;
    }
    .right_image>img{
        position:relative;
        width: 15rem;
        left: 6rem;
        top: -3rem;
    }
    .arrow3{
        width: 10rem;
        top: 9.5rem;
    }
}