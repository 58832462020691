/* Header = logo + header-menu */
.Header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 8rem;
    height: 8rem;
}

.Header-Menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: rgb(231, 238, 255);
    margin-right: 5rem;
}

.Header-Menu>li:hover{
    color: orange;
    cursor: pointer;
    
}

@media screen and (max-width: 988px){
    .Header:nth-child(2){
        position: fixed;
        visibility: visible;
        background-color: grey;
        z-index: 99;
        right: 2rem;
        width: 1rem;
    }
    .Header>div>img{
        display:flex;
        width: 3rem;
        margin: 3rem;
    }

    

    .Header-Menu{
        flex-direction: column;
        background-color: grey;
        visibility: visible;
        padding: 2rem;
        z-index: 99;
        margin-right: 0rem;
    }
}
